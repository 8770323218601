<template>
    <section class="py-5" style="padding-top: 0rem !important;background: #9DB0B0;">
        <top-home />
    </section>
    <section class="pb-5">
        <puzzles-home />
    </section>
    <section class="pb-5">
        <strategy-home/>
    </section>
    <section class="pb-5">
        <sports-home/>
    </section>
    <section class="pb-5">
        <adventure-home/>
    </section>
    <section class="pb-5">
        <arcade-home/>
    </section>
</template>

<script>
import { watchEffect } from '@vue/runtime-core'
import { HTTPSPA } from '@/Api/http-Spay';
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
import { useToast } from "vue-toastification";
import AdventureHome from './HomePage/AdventureHome.vue'
import ArcadeHome from './HomePage/ArcadeHome.vue'
import PuzzlesHome from './HomePage/PuzzlesHome.vue'
import SportsHome from './HomePage/SportsHome.vue'
import StrategyHome from './HomePage/StrategyHome.vue'
import TopHome from './HomePage/TopHome.vue'
export default {
  components: { TopHome, PuzzlesHome, StrategyHome, SportsHome, AdventureHome, ArcadeHome },
   name: 'AppHome',
    setup() {
        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie()
        const toast = useToast();
       watchEffect(() => {
            if (!cookie.isCookieAvailable("msisdn") && !cookie.isCookieAvailable("status")) {
            let urlParams = new URLSearchParams(window.location.search);
            // console.log(urlParams.get('msisdn'))
            if(urlParams.get('phoneNumber') != null) {
                 if(urlParams.get('phoneNumber').length === 12) {
              // console.log(urlParams.get('phoneNumber'))
              if(urlParams.has('phoneNumber')) {
                // HTTPSPA.post(`CheckLogin.php?username=2491`+urlParams.get(`phoneNumber`) +`&serviceId=2222&operatorID=2`).then((res) => {
                HTTPSPA.post(`CheckLogin.php?username=`+urlParams.get(`phoneNumber`) +`&serviceId=2222&operatorID=2`).then((res) => {
                    if (res.data.status == 1 ) {
                        cookie.setCookie('msisdn', urlParams.get('phoneNumber'), { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('status', res.data.status, { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('MINTUES', res.data.MINTUES, { expire: 60 * res.data.MINTUES, })
                        // toast.success("مرحبا بك معنا  ", { timeout: 1000 });
                    } else if (res.data.status == 0 && res.data.responseCode == 118) {
                        cookie.setCookie("msisdn", `2491`+urlParams.get('phoneNumber'), { expire: 60 * 120, });
                        toast.warning("عفوا انت مشترك مسبقا ولم يتم تجديد اشتراكك", { timeout: 2500, });
                    } else {
                        toast.info("عفوا انت لست مشترك في خدمة تاق بريس الاخبارية" , { timeout: 2500 });
                    }
                    if(cookie.getCookie("Content_id")!=null){
                        toast.info("سوف يتم تحويلك الي المحتوي", { timeout: 2000 });
                        setTimeout(() =>  router.push({name: "Content", params: { id: cookie.getCookie("Content_id") } }), 2000);
                    } else {
                        setTimeout(() => router.push({ path: "/" }), 2500);
                    }
                })
            }
            } else {
              if(urlParams.has('phoneNumber')) {
                HTTPSPA.post(`CheckLogin.php?username=2491`+urlParams.get(`phoneNumber`) +`&serviceId=2222&operatorID=2`).then((res) => {
                // HTTPSPA.post(`CheckLogin.php?username=`+urlParams.get(`phoneNumber`) +`&serviceId=2222&operatorID=2`).then((res) => {
                    if (res.data.status == 1 ) {
                        cookie.setCookie('msisdn', `2491`+urlParams.get('phoneNumber'), { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('status', res.data.status, { expire: 60 * res.data.MINTUES, })
                        cookie.setCookie('MINTUES', res.data.MINTUES, { expire: 60 * res.data.MINTUES, })
                        // toast.success("مرحبا بك معنا  ", { timeout: 1000 });
                    } else if (res.data.status == 0 && res.data.responseCode == 118) {
                        cookie.setCookie("msisdn", `2491`+urlParams.get('phoneNumber'), { expire: 60 * 120, });
                        toast.warning("عفوا انت مشترك مسبقا ولم يتم تجديد اشتراكك", { timeout: 2500, });
                    } else {
                        toast.info("عفوا انت لست مشترك في خدمة تاق بريس الاخبارية" , { timeout: 2500 });
                    }
                    if(cookie.getCookie("Content_id")!=null){
                        toast.info("سوف يتم تحويلك الي المحتوي", { timeout: 2000 });
                        setTimeout(() =>  router.push({name: "Content", params: { id: cookie.getCookie("Content_id") } }), 2000);
                    } else {
                        setTimeout(() => router.push({ path: "/" }), 2500);
                    }
                })
            }
            }
            }
            
          } 
        //   else {
        //       HTTPSPA.post(`CheckLogin.php?username=`+cookie.getCookie("msisdn")+`&serviceId=2222&operatorID=2`).then((res) => {
        //         if (res.data.status === 0 ) {
        //             this.$cookie.keys().forEach(cookie => this.$cookie.removeCookie(cookie))
        //         }
        //       })
        //   }
        });
   }
}
</script>

<style>

</style>