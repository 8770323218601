<template>
   <section class="fuckU_footer py-4 osahan-copyright" style="background: #0b77ac;padding-top: 2.5rem !important;">
    <div class="container">
        <div class="row" dir="rtl">
            <div class="col-xl-12">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 style="color: #fff;text-shadow: 1px 1px 0px #00000080;">اكتشف حسب النوع</h5>
                    <p style="color: #fff;text-align: right;text-shadow: 1px 1px 1px #00000080;">
                        أعلي كل صفحة ستجد الفئات الرئيسية مثل الرياضة الالغاز الاكشن و السباق   . 
                    </p>
                </div>
            </div>
            <div class="col-xl-12">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 style="color: #fff;text-shadow: 1px 1px 0px #00000080;">معلومات عنا</h5>
                    <p style="color: #fff;text-align: right;text-shadow: 1px 1px 1px #00000080;">
                         لعبتنا هو موقع ويب للألعاب عبر الإنترنت يقدم أفضل مجموعة مختارة من الألعاب  لتجربة أكثر إمتاعًا. أينما كنت ، يمكنك قضاء يوم كامل على لعبتنا، نحن نقدم اللعب الفوري دون تنزيل أو مكونات إضافية أو تسجيل دخول أو نوافذ منبثقة أو أي إلهاء. يمكنك اللعب على أي جهاز ، وجهاز الكمبيوتر المحمول ، والجهاز اللوحي ، والهاتف الذكي. نحن نقدم أفضل خدمة العاب على الإنترنت. لدينا مجموعة كبيرة من الألعاب على الإنترنت  مناسبة لجميع الأعمار وجميع المستويات
                    </p>
                </div>
            </div>
            <div class="col-xl-12">
                <div class="d-flex align-items-center justify-content-between">
                    <p>
                    </p>
                    <p class="m-0">
                        <a href="https://sudani.sd/" class="text-secondary"><img style="width: 60px;" src="../../assets/image/Sudani-Logo.png" alt=""/></a>
                        <router-link to="/" class="navbar-brand mr-2" style="font-size: 10px;font-weight: 600;color: #fff;"><img style="width: 47px;margin-right: 30px;" src="../../assets/image/GameClubLogo.png" alt="">
                        </router-link>
                    </p>
                    <p class="m-0 text-muted">Powered by <a class="text-dark" target="_blank" href="#"> Sudani</a></p>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
export default {
   name: 'AppFooter'
}
</script>

<style>


</style>